div.selection-modal{
    height: 80vh;
    width: 90%;
    border-radius: 4px;


}
form.center-stuff{
    display:flex;
    justify-content: center;
    align-items: center;
    height: calc(80vh - 120px);
    width: 100%;
}
section.modal-bottom{
    height: 30px;
    width: calc(100% - 40px);
    display: flex;
    justify-content: space-between;
    align-items: center;    
    padding: 20px;
    border-top: 1px solid lightgray;
    background-color: white;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
}
section.modal-main{
    height: calc(80vh - 120px);
    overflow-y: scroll;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: white;
    width: 100%;
}
section.acct-tabs.on-modal{
    border-top-right-radius: 4px;
    border-top-left-radius: 4px;
}
/* section.acct-tabs, section.modal-main, section.modal-bottom{
width: 100%
} */
.square-photo-container{
    width: 20%;
    min-width: 200px;
    height: 20%;
    min-height: 200px;
    position: relative;
    color: transparent;
    text-shadow: none;
    margin: 20px;
}
.square-photo-container.selected{
    border: 4px solid aqua;
    width: calc(20% - 8px);
    min-width: 192px;
    height: calc(20% - 8px);
    min-height: 192px;

}
.square-photo-close-out{
    position: absolute;
    top: 5px;
    right: 0px;
    border: none;
    background-color: transparent;
    color: white;
    text-shadow: inherit;
    cursor: pointer;
}
.square-photo-close-out:hover{
    text-shadow: 0px 0px 1px white;
}
.square-photo-container:hover{
    color: white;
    text-shadow: 1px 1px 1px black;
    cursor: pointer;
}
.square-photo-title, .square-photo-user{
    color: inherit;
    font-size: 12px;
    text-shadow: inherit;
    position: absolute;

}
.select-check{
    color: white;
    text-shadow: none;
    background-color: aqua;
    padding: 2px;
    position: absolute;
    top: 0%;
    margin: 0;
    right: 0;
}
.square-photo-title{
    bottom: 8%;
    left: 3%
}
.square-photo-user{
    bottom: 1%;
    left: 3%
}
img.square-photo{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.modal-close-out{
    position: absolute;
    right: 10px;
    text-decoration: none;
}
button.modal-close-out:hover{
    border-bottom: none;
    cursor: pointer;
    padding-top:0;
}
.no-albums{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

