.prof-pic-holder{
    position: relative;
    text-shadow: none;
    color: transparent;
}
.prof-pic-holder:hover{
    color: white;
    text-shadow: 0px 0px 10px black;
    cursor: pointer;
}

p.edit-pencil{
    position: absolute;
    top: 25%;
    left: 30%;
    font-size: 25px;
    text-shadow: inherit;
    color: inherit;
}
p.edit-pencil i{
    color: inherit;
}



nav.triple-dots{
    position: absolute;
    left: 58%;
    top: 78%;
    transform: translate(-50%, 0.75rem);
    z-index: 1;
    background-color: white;
    border: 1px solid lightgray;
    border-radius: 3px;
    
}
nav.triple-dots a, nav.triple-dots p{
    font-size: 15px;
    text-align: left;
    color: black;
    width: 100%;
    padding: 5px 10px 5px 10px;
    text-decoration: none;
    border: none;
    border-radius: 0;
    margin: 0 0 0 0 ;
    background-color: transparent;
    cursor: pointer;
}
nav.triple-dots .list-button{
    padding: 4px 0px 4px 0;
    
}
nav.triple-dots .list-button:hover{
    width: 100%;
    background-color: rgb(238, 238, 238);
}





.banner-pic{
    width: 100%;
    color: white;
    display: flex;
    padding: 50px 0 10px 0;
    justify-content: space-between;
    align-items:flex-end;
    height: 250px;
    background-position: center center;
    background-size: cover;
}
.right-subheader{
    display:flex;
    flex-direction: row;
    margin-right:10vw
}
.subheader-name{
    margin: 10px;
    font-size: 30px;
    font-weight: 100;
}

.left-subheader{
    margin-left: 10%;
    display: flex;
    z-index: 2;
}
.prof-pic{
    height:100px;
    border-radius: 100%;
    width: 100px;
    object-fit: cover;
}
.banner-pic p{
    margin:10px
}
.l-sh-text{
    display: flex;
    flex-direction: column;
}
.l-sh-text-top{
    position: relative;
    width: 450px;
    display: flex;
    align-items: center;
}
.l-sh-text-top button{ 
    height: 35px;
    width: 40px;
    color:white;
    background-color: transparent;
    border: 2px solid white;
    border-radius: 3px;
    margin: 10px 10px 10px 20px;
}
.l-sh-text-top button:hover{ 
    color: black;
    background-color: white;

}
.l-sh-text-bottom{
    display: flex;
}