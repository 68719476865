.delete-page{
    padding: 200px;
    height: calc(100vh - 400px);
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.delete-page button{
    color: white;
    background-color: rgb(30, 113, 201);
    border: none;
    padding: 5px;
}