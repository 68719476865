.splash-center, .form-flex{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
}
.readable{
    text-shadow: 1px 1px 1px black;
}
.splash-center{
    font-family: Proxima Nova, helvetica neue, helvetica, arial, sans-serif;
    color: white;
    text-align: center;
}
.splash-center h1{
    max-width: 600px;
    font-weight: 400;
    font-size: 56px;
    margin: 20px;
}
.splash-center h2{
    max-width: 600px;
    font-weight: 400;
    font-size: 23px;
}
.splash-center a{
    text-decoration: none;
    color: black;
    background-color: white;
    padding: 20px 30px;
    border-radius: 3px;
    font-weight: 600;
    font-size: 23px;
    margin: 20px;
}
.splash-center a:hover{ 
    color: grey
}
.splash .right-header{
    display: flex;
    align-items: center;
    font-weight: 300;
    margin-right: 10%;
}
.splash .login-header{
    font-size: 15px;
    font-weight: bold;
    width: 60px;
    font-weight: 300;
}
.splash .signup-header{
    font-size: 15px;
    width: 80px;
    font-weight:bold;
    color: black;
    background-color: white;
    padding: 7px 15px;
    border-radius: 3px;
    text-align: center;
}