/* TODO Add site wide styles */
body{
    margin: 0;
    background-color: rgb(77, 77, 130);
}
div, nav, section, button, a, h1, h2, h3, h4, h5, input{
    font-family: Arial, Helvetica, sans-serif;
}
ul, li {
    list-style: none;
    margin:none;
    padding:none;
}
