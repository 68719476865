.inner-content-box img.acct-prof-pic{
    height:70px;
    width:70px;
    border-radius: 100%;
    object-fit: cover;
}
.inner-content-box p.email{
    margin-top: 20px;
}
.p2{
    padding-left: 10px;
}
.content-box button.profile{
    border: none;
    color: white;
    font-size: 30px;
    margin: 0 0 0 0px;
    border-radius: 25px;
    padding: 6px 10px;
    height: 48px;
    width: 48px
}
.content-box button img{
    height: 48px;
    border-radius: 25px;
}
.content-box button img:hover{
    box-shadow: inset 0 0 10px 10px rgba(0,0,0,0.5)
}
.take-up-space{
    height: 100vh
}
.flex-cols{
    display: flex;
    justify-content: flex-start;
    padding: 10px
}
.inner-left-column{
    margin-left: 25%;
}
.request{
    margin-top: 20px;
    margin-left: 20px;
    color: white;
    background-color: rgb(18, 143, 220);
    border: none;
    border-radius: 4px;
    padding: 10px 15px;
    font-weight: 900;
}
.request:hover{
    cursor: pointer;
    background-color:rgb(34, 152, 225);
}
.inner-content-box p.email{
    margin-top: 20px;
    margin-bottom: 20px;
}
.inner-content-box{
    padding-top: 20px;
    padding-bottom: 20px;
}
.content-box p.benefit{
    margin: 0 0 0 15px;
    color: grey;
}
.content-box p.company{
    margin: 0 0 0 15px;
}
.inner-content-box img{
    height: 45px;
}
.inner-content-box{
    border-bottom: 1px solid rgb(224, 224, 224);
}
.inner-content-box:last-child{
 border-bottom: none;
}

.content-box{
    margin-top: 20px;
    border: 1px solid rgb(224, 224, 224);
    border-radius: 4px;
    background-color: white;
}
.content-box p{
    line-height: 25px;
    font-size: 13px;
    margin: 5px 15px;
}
.content-box a{
    line-height: 25px;
    text-decoration: none;
    color: rgb(23, 128, 193);
}
.content-box a:hover{
    text-decoration: underline;
}
.content-box strong{
    font-size: 13px;
    margin:  0 15px;
}
.pink-circle{
    margin: 0 10px 0 10px;
    padding: 2px;
    background: linear-gradient(135deg,#ff84c4,#ef096d);
    border-radius: 27px;
    height: 46px;
    width: 48px;
}
.inner-circle{
    color: #ff46a6;
    font-size: 13px;
    font-weight: 600;
    line-height: 46px;
    background-color: #fff;
    border-radius: 23px;
    height: 46px;
    margin: 0;
    padding: 0;
    text-align: center;
    width: 46px;}
.content-box header{
    border-bottom: 1px solid rgb(224, 224, 224);
}
.content-box h1{
    padding-left: 20px;
    font-weight: 100;
    font-size: 18px;
}

.acct-settings{
    padding-left: 10%;
    padding-top: 50px;
    font-size: x-large;
    color: black;
    font-weight: light;
    background-color: white;
    height: 114px;
    display: flex;
    align-items: center;
}
.acct-settings h1{
    font-size: x-large;
    color: black;
    font-weight: 100;
}
.acct-tabs{
    height: 50px;
    background-color: white;
    display: flex;
    justify-content: flex-start;
    padding-left: 10%;
    z-index: 1;
    position: sticky;
    top: 50px; 
    position: -webkit-sticky;
    border-bottom: 1px solid rgb(224, 224, 224);
}
.acct-tabs button, .acct-tabs a{
    background-color: transparent;
    border: none;
    box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
    height: 50px;
    padding: 0 20px;
    font-size: 15px;
    color: rgb(79, 79, 79);
    margin-right: 10px;
    font-weight: 100;
    text-decoration: none;
}
.acct-tabs button:hover, .acct-tabs button.active, .acct-tabs a:hover, .acct-tabs a.active{
    border-bottom: 3px solid rgb(18, 143, 220);
    color: black;
    padding-top:3px;
    /* box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box; */
}
.acct-tabs a:hover, .acct-tabs a.active{
    padding-top: 15px
}
.acct-tabs a{
    padding-top: 15px
}
.left-column{
    margin-left:6%;
    width: 39%;
    margin-right: 20px;

}
.right-column{
    margin-right: 6%;
    width: 39%;   
}
.content{
    padding-top: 15px;
    padding-bottom: 30px;
    height: fit-content;
    min-height: 1000px;
    background-color: rgb(243, 245, 246);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
}
.flex{
    padding-left: 20px;
    display: flex;
    align-items: center;
}
strong.inline-strong{
    margin: 0
}