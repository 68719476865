.showcase-get-started{
    color:rgb(30, 113, 201);
    margin-top: 0;
}
.edit-user-info-button{
    position: absolute;
    
    right: 6%;
    top: 30px;
    font-size: 20px;

}
.about-info-data-inputs{
    display:flex;
    flex-direction: column;
}
.info-button-section{
    width: 100%;
    display:flex;
    justify-content: flex-end;
}
.about-info-input{
    margin: 0px 0px 5px;
    width: 200px;
    height: 25px;
}
.about-info-data-inputs.last{
    margin-top: 6px;
}
.less-margin-top{
    margin-top: 5px
}
.less-bottom-margin{
    margin-bottom: 5px;
}
.center-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}
.showcase-get-started:hover{
    text-decoration: underline;
    cursor: pointer;
}
.space-btwn{
    display: flex;
    justify-content: space-between;
}
section.description-button-section{
    display: flex;
    justify-content: flex-end;
}
.edit-user-info-button{
    color: gray;
    background-color: transparent;
    border: none;
}
.edit-user-info-button:hover{
    color: rgb(148, 147, 147);
    cursor: pointer;
}
.testimonial-toggle{
    color: rgb(30, 113, 201);
}
.testimonial-toggle:hover{
    text-decoration: underline;
}
div.center-column{
    width: 80%;
    background-color: white;
    display: flex;
    flex-direction: column;
    border: 1px solid lightgray;
    border-radius: 4px;
    margin-top: 20px;
}
.popular-photos, .about-showcase{
    width: 80%;
}
.popular-photos .photo-column{
    margin-right: 0;
    margin-left: 0;
    width: 100%;
}
.photo-column{
 width: 100%;
}
.about-showcase .photo-column{
    margin-right: 0;
    margin-left: 0;
    width: 80%;
    display: flex;
    flex-wrap: wrap;
}
.photo-column .popular-photo{
    max-width: calc(50% - 5px);
}
.popular-photo img{
    width: 100%;
    
}
.description-form, .description-input{
    width: 100%
}
.description-input{
    height: 130px;
    resize: vertical;
}
.popular-photo{
    margin:2px
}
.center-column.labeled{
    margin-top: 0;
}
div.section-label{
    width: 80%;
    display: flex;
    padding: 0;
    margin: 30px 0 10px;
}
.section-label.with-toggle{
    justify-content: space-between;
}
.section-label h2{
    margin: 0;
    font-weight: 500;
    font-size: 20px;
}
.about-section{
    border-bottom: 1px solid lightgrey;
    position: relative;
}
.about-section:last-child{
    border: none;
}
.about-info{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    
}
.side-by-side{
 display: flex;
 margin-right: 0;
}
.about-info div{
    margin-right: 40px;
}
.about-info div.last{
    margin-right: 0;
}

.about-info-labels{
    color: gray;
}
.content.column{
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}
.about-stats{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
}
.about-section{
    padding: 20px 10%
}
.about-stat{
    margin: 0px 30px;
}
.about-stat:first-child{
    margin-left: 0;
}
.about-stat h2{
    margin: 0;
    font-weight: 500;
}
.about-stat p{
    margin: 0;
    color:gray;
}
.about-stat:hover{
    text-decoration: underline;
    cursor: not-allowed;
}
.showcase-photo{
    max-width: calc(50% - 5px);
    margin: 2px;
}
img.showcase{
    max-height: 100%;
    max-width: 100%;
}