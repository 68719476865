
.hidden-clue{
    bottom: -80px;
    left: 3%;
    position: absolute;
    color: white;
    font-size: 20px;
    
}
.back-arrow{
    position: relative;
    right: 150px;
    color: gray;
    border-radius: 15px;
    padding: 6px 8px;
    margin: 0;
}
.fa-arrow-left, .fa-lock{
    color:gray;
}
.back-arrow:hover{
    background-color: lightgray;
}
.back-arrow a{
    color: gray;
    font-size: 15px;
}

.login-form.password-reset{
    border: 1px solid lightgray
}
.fname-label, .lname-label, .age-label, .email-label, .password-label, .cp-label, .np-label{
    color: grey;
    font-size: 11px;
    position: relative;
    top: 20px;
    padding: 0;
    margin: 0;
    height: 8px;
}
.cp-label{
    right: 96px;
}
.np-label{
    right: 102px;
}
.email-label{
    right: 105px;
}
.password-label{
    right: 115px;
}
.fname-label, .lname-label{
    right: 112px;
}
.age-label{
    right: 118px;
}
.header-bar.splash a{
    margin-left: 5%;
}
.login-form{
    font-family: Arial, Helvetica, sans-serif;
    padding: 10px;
    width: 330px;
    display: flex;
    flex-direction: column;
    background-color: white;
    border-radius: 5px;
    align-items: center;
    text-align: center;
    position: relative;
}
.login-form h3{
    font-weight: 400;
    font-size: larger;
    font-family: Arial,Helvetica,sans-serif;
}
.login-form input{
    width: 290px;
    height: 35px;
    border-radius: 4px;
    border: 1px solid grey;
    font-size: medium;
    padding-inline-start: 15px;
    padding-top: 20px;
}
.login-form input:focus{
    border: solid 1px rgb(18, 143, 220);
}
.login-form input.angry{
    border: solid 1px rgb(199, 3, 3)
}
.login-form p{
    margin-top: 0;
    font-size: smaller;
}
.login-form a{
    text-decoration: none;
    color: rgb(11, 102, 159);
}
.form-logo{
    margin-top: 20px;
}
.submit-button{
    background-color:rgb(18, 143, 220);
    width: 309px;
    border: none;
    color: white;
    font-weight:bold;
    padding: 12px;
    border-radius: 4px;
    margin: 10px;
    margin-bottom: 20px;
    font-size: medium;
}
.submit-button:hover{
    cursor: pointer;
    background-color:rgb(34, 152, 225);
}
.forgot{
    width: 305px;
    padding-bottom: 5px;

    color: rgb(11, 102, 159);
}
.forgot:hover{
    cursor: pointer;
}
.terms{
    width: 250px;
    padding-bottom: 15px;
   
}
p.terms{
    color: grey;
    font-size: 12px;
}
.logo{
    font-size: x-small;
    position: relative;
    top: -3px;
    
}
.form-flex{
    background-image: url(https://flickster-bg-pics.s3.us-east-2.amazonaws.com/airplane-view.jpg
    );
    background-size: cover;
}
.form-flex.password-reset{
    background-image: none;
    background-color: rgb(229, 233, 237);
}
.error{
    background-color: rgb(248, 216, 222);
    width: 300px;
    margin: 3px;
    border-radius: 4px;
}
.error p{
    margin: 10px;
    font-size: smaller;
}
input.check{
    width: 15px;
    height: 15px;
    align-self: flex-start;
    margin: 10px
}
label.check-label{
    display: inline;
    font-size: smaller;
    font-weight:lighter ;
    position: relative;
    top: -25px;
    left: -56px;
}
.below-form{
    width: 350px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
    font-size: 15px;
    font-family: Proxima Nova ,Helvetica,sans-serif;
}
.below-form a{
    margin: 5px;
    color: white;
    text-decoration: none;
}
.below-form.password-reset{
    color: rgb(153, 153, 153);
}
.below-form.password-reset a{
    color: rgb(153, 153, 153);
}
.member{
    margin: 0;
    padding: 20px 0 15px 0;
    border-top: 1px solid lightgrey;
    width: 300px;
}