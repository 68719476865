div.photo-stats{
    display: flex;
    width: 100%;
}


.photo-stat{
    margin-right: 40px
}
.photo-stat h3{
    font-weight: 500;
    margin-bottom: 0;
}
.photo-stat h4{
    font-weight: 300;
    white-space: nowrap;
}
.photo-stat:last-child{
    margin-right: 0;
}
.photo-stat p{
    margin-top: 5px;
    font-size: 15px;
    color: rgb(104, 104, 104);
}
.delete-form{
    height:185px;
    width: 600px;
    border-radius: 3px;
    border: 1px solid lightgrey;
    background-color: white;
    padding: 20px;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.cover-screen{
    height: 110vh;
    width: 100%;
    background-color: rgba(73, 73, 73, 0.691);
    position: absolute;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
}
.delete-form-mid{
    height: 120px;
}
.delete-form-top{
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
button.x-out{
    background-color: transparent;
    color: gray;
    font-size: 20px;
    border:none;
}
.x-out:hover{
    cursor: pointer;
    color: rgb(97, 97, 97);
}
.delete-form-top h3{
    margin-top: 0;
    font-weight: 100;
    font-size: 20px;
}
.cancel, .delete{
    border:none;
    color: white;
    font-weight: 700;
    padding: 5px 20px;
    margin-left: 10px;
    border-radius: 3px;
}
.delete{
    background-color: rgb(224, 34, 34);
}
.delete:hover{
    background-color: rgb(228, 57, 57);
}
.cancel{
    background-color: rgb(95, 95, 95)
}
.cancel:hover{
    background-color: rgb(142, 142, 142)
}

.delete-form-bottom{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}
.point-down{
    border-bottom: 0px solid transparent;
    border-top: 12px solid white;
    position: absolute;
    bottom: -11px;
    right: 5px;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
}


nav.edit-popup{
    right: 9%;
    bottom: 55px;
    position:absolute;
    height:fit-content;
    background-color: white;
    border-radius: 3px;
    border: 1px solid lightgrey;
    width: 150px;
}
.list-button:hover{
    width: 100%;
    background-color: rgb(238, 238, 238);
}
.delete-button{
    color:rgb(203, 13, 13);
    background-color: transparent;
    border: none;
}
div.user-info{
    display:flex;
    flex-direction:row;
}
.under-button{
    position:absolute;
    bottom: 15px;
    right: 9%;
    
    display:flex;
    gap: 20px;
}
.edit-button, .fave-button{
    background-color: transparent;
    border: none;
    color: white;
    font-size: 18px;
}
.edit-button:hover, .fave-button:hover{
    cursor: pointer;
}
div.info-text{
    margin-left:10px;
}
.ps-title{
    display: block;
    font-size: 16px;
    color:black;
    font-weight:400;
    margin: 0;
    padding: 5px 5px 5px 5px;
}
.ps-title:hover{
    display: block;
    background-color: lightgray;

}
.ps-title:hover::after{
    content:" ✎"
}
.ps-user-name{
    margin-top: 0;
    margin-bottom: 0;
    font-size: 25px;
    text-decoration: none;
    color: black;
    font-weight: 300;
}
.ps-user-name:hover{
    color: rgb(64, 160, 224);
    text-decoration: underline;
}

section.photo-show-bottom{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}
section.ps-left-column, section.ps-right-column{
    width: calc(50% - 20px);
    margin: 10px 20px 0 0;
}
section.ps-right-column{
    margin-right: 0;
}
.photo-background{
    position: relative;
    top:0px;
    background-color: rgb(43, 43, 46);
    padding-top: 0px;
    height: 800px;
    width: 100%;
    display:flex;
    justify-content: space-between;
    align-items: center;
}
.hero-image{
    height: 700px;
    max-width: 1000px;
}
.contents.photo-show{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 50px;
    background-color: aliceblue;
}
.previous-button, .next-button{
    height: 800px;
    background: none;
    border: none;
    color: rgb(211, 209, 209);
    width: 50px;
    font-size: 20px;

}
.previous-button{
    padding-left: 30px;
}
.next-button{
    padding-right: 30px;    
}
.previous-button:hover, .next-button:hover{
    cursor: pointer;
    color:white;

}
div.arrow-back{
    position: absolute;
    top: 10px;
    left: 40px;
    color:white;
    font-size: 12px;
    display: flex;
    align-items: center;
}
div.arrow-back .fa-arrow-left{
    color: white;
}
div.arrow-back p:hover{
    text-decoration: underline;
}
div.arrow-back:hover{
    cursor: pointer;
}
div.arrow-back p{
    padding: 0 10px;
}
img.prof-pic-60-circle{
    margin-top: 10px;
    height:60px;
    
    width:60px;
    border-radius: 100%;
    object-fit: cover;
}