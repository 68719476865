.footer{
    background-color: rgb(33, 33, 36);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.footer-top, .footer-bottom{
    height:50px;
    width:90%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    color:gray;
}
.footer-top{
    border-bottom: 1px solid gray;
}
.footer button, .footer a{
    background-color: transparent;
    border: none;
    color: gray
}
.footer button:hover, .footer a:hover{
    color:lightgray;
}
.footer a{
    text-decoration: none;
    margin: 0 5px
}
.disabled:hover{
    cursor: not-allowed;
}