.need-help{
    font-size: 12px;
}
td.small{
    width: 10px;
}
.occupation-input{
    width: 180px
}
.table-label.airport{
    width: 150px
}
.airport-input{
    width: 45px;
}
.airport-input-holder{
    width: 200px
}

p.helper{
    margin: 0;
    font-size: 11px;
    color: rgb(155, 155, 155);
}
.first-name-input{
    display: flex;
    justify-content: flex-start;
    width: 430px
}
.table-label{
    width: 210px;
}
.table-label.singleness{
    width: 0px
}
.website-input{
    width: 213px
}
.webinput{
    width: 610px
}
.epf-bottom{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.description{
    width: 350px;
    height: 150px;
    border-radius: 0;
}
.radio-section{
    display: flex;
    flex-direction: column;
}
.edit-prof-form{
    padding-top: 60px;
    background-color: white;
    display: flex;
    flex-direction: column;
    width: 800px;
    margin: auto;
}
.time-zone{
    width: 343px;
}
.flexcol{
    width: 350px
}
label.epf{
    font-size: 12px;
}
.whitebg{
    background-color: white;
    width: 100%;
    height: fit-content;
    padding-bottom: 100px;
}
h3.epf{
    color:rgb(255, 0, 132);
    font-weight: 100;
}
.table-label{
    display: flex;
    justify-content: flex-end;
    font-size: 13px;
    font-weight: bold;
    padding: 0 10px 10px;

}
header.epf{
    display: flex;
    align-items: center;
}
.epf a{
    text-decoration: none;
    color:rgb(0, 99, 220);
}
.epf a:hover{
    text-decoration: underline;
}
p.epf{
    font-size: 12px;
    margin-left: 5px
}
h1.epf{
    font-size: 25px;
}
.save-button{
    margin-top: 20px;
    margin-left: 20px;
    color: white;
    background-color: rgb(0, 99, 220);
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    font-weight: 900;
    width: fit-content;
}
.save-button:hover{
    cursor: pointer;
    background-color:rgb(8, 89, 189);
}
td{
    padding-bottom: 10px;
}