.header-bar.logged-in .right-header nav a.nostyle{
    width: 100%;
    padding: 0;
    margin: 0;
}
.header-bar.logged-in .right-header nav a{
    font-size: 15px;
    color: black;
    font-weight: 100;
    padding: 0;
    margin: 0px 0px 0px 15px;
}
.header-bar nav{
    position: absolute;
    list-style: none;
    background-color: white;
    font-family: Arial, Helvetica, sans-serif;
    text-align: left;
    border-radius: 4px;
    width: 300px;
    right: 2%;
    top: 55px;
    border: 1px solid grey;
}
.header-bar nav h1{
    font-size: 20px;
    padding: 5px 15px 0px 15px;
    margin-bottom: 0;
}
.header-bar nav .greeting{
    width: 100%;
    border-bottom: 1px solid lightgray;
}
.header-bar nav h3{
    font-size: 15px;
    font-weight: lighter;
    color: rgb(186, 186, 186);
    padding: 0px 15px 0px 15px;
    margin-top: 5px;
}
.header-bar.logged-in .right-header nav button{
    font-size: 15px;
    color: black;
    font-weight: 100;
    padding: 0;
    margin: 0px 0px 0px 15px;
}

.header-bar.logged-in .right-header nav div.point{
    border-bottom: 12px solid white;
    position: absolute;
    top: -9px;
    left: 270px;
    border-left: 11px solid transparent;
    border-right: 11px solid transparent;
    
}
.listbutton{
    height: fit-content;
    width: 100%;
    padding: 5px 0px 5px 0
}
.listbutton:hover{
    background-color: rgb(238, 238, 238);
}